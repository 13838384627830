<script setup lang="ts">
import type { CookieRef } from '#app'
import { toTypedSchema } from '@vee-validate/zod'
import { useField, useForm } from 'vee-validate'
import * as zod from 'zod'

// Props
const props = defineProps<{
  title: string
  terms: string
  travelAgent: string
  image: string
  form: string
}>()

// State
const config = useRuntimeConfig()
const signupPopupEnabled = config.public.atlantisEnableSignupPopup === 'true'
const aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
const seenCookie: CookieRef<boolean> = useCookie(`seen-popup-${props.form}`, { expires: aYearFromNow })
const { $csrfFetch } = useNuxtApp()
const show = ref(false)
const validationSchema = toTypedSchema(
  zod.object({
    first_name: zod.string({ message: '*First Name is required' }).refine(val => val.trim() !== '', { message: '*Must be a valid First Name' }),
    last_name: zod.string({ message: '*Last Name is required' }).refine(val => val.trim() !== '', { message: '*Must be a valid Last Name' }),
    email: zod.string({ message: '*Email is required' }).min(1, { message: '*Must be a valid Email' }).email({ message: '*Must be a valid Email' }),
    zipcode: zod.string({ message: '*Zip Code is required' }).min(5, { message: '*Must be a valid Zip Code' }),
    agree_to_terms: zod.boolean({ message: '*Must agree to terms.' }).refine(val => val === true, { message: '*Must agree to terms.' }),
  }),
)
const { handleSubmit, errors } = useForm({ validationSchema })
const onSubmit = handleSubmit(() => submitForm())
const step = ref(1)
const formId = ref('')
const isTravelAgent = ref(false)
const { value: first_name } = useField('first_name')
const { value: last_name } = useField('last_name')
const { value: email } = useField('email')
const { value: zipcode } = useField('zipcode')
const { value: agree_to_terms } = useField('agree_to_terms')

// Computed
const formData = computed(() => {
  return {
    id: formId.value as string,
    form_name: props.form as string,
    first_name: first_name.value as string,
    last_name: last_name.value as string,
    email: email.value as string,
    zipcode: zipcode.value as string,
    agree_to_terms: agree_to_terms.value as boolean,
    isTravelAgent: isTravelAgent.value as boolean,
  }
})

// Methods
function close() {
  show.value = false
  seenCookie.value = true
}
function setNextStep() {
  step.value += 1
}
function submitForm() {
  const data = {
    form_name: props.form,
    datasource: 'FORMDATA',
    subscription: {
      email: email.value,
      first_name: first_name.value,
      last_name: last_name.value,
      phone: null,
      can_text: false,
      can_call: false,
      zipcode: zipcode.value,
      isTravelAgent: isTravelAgent.value,
      terms_accepted: true,
      subscribed: true,
    },
  }

  $csrfFetch('/api/subscriptions/new', { method: 'post', body: data })
    .then((data: any) => {
      formId.value = data.data[0].insertId
      setNextStep()
    })
    .catch((e) => {
      const { errors: requestErrors } = e.data
      requestErrors.forEach((err: any) => {
        const field = err.path[1] as keyof typeof errors.value
        if (field)
          errors.value[field] = `Issue with ${field}: ${err.message}`
      })
    })
}

// Lifecycle
onMounted(() => {
  if (signupPopupEnabled) {
    if (seenCookie.value)
      show.value = false
    else
      show.value = true
  }
})
</script>

<template>
  <div>
    <ClientOnly>
      <Teleport v-if="show" to="#portal-modal">
        <div class="fixed z-[99999] inset-0 flex justify-center items-center">
          <div class="absolute inset-0 z-0 bg-black/60 cursor-pointer" @click="close" />
          <div class="relative z-10 max-w-[100vw] lg:max-w-[800px] lg:min-h-[400px] px-2">
            <div class="relative z-10 grid md:grid-cols-12 md:gap-12 bg-white shadow-3xl min-h-[400px]">
              <button
                class="absolute top-0 right-0 bg-black text-white rounded-full translate-y-1 lg:-translate-y-4 -translate-x-1 lg:translate-x-4 cursor-pointer"
                @click="close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  class="w-10 h-10"
                >
                  <path
                    stroke-linecap="round" stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
              <div class="hidden md:block relative md:col-span-5">
                <div class="absolute inset-0 bg-red-500">
                  <img :src="image" class="object-cover w-full h-full">
                </div>
              </div>
              <div v-if="step === 1" class="flex flex-col space-y-8 md:col-span-7 font-sans2 py-8 lg:pr-8">
                <div>
                  <h2 class="headline-2 text-center text-glueblue-600">
                    {{ title }}
                  </h2>
                </div>
                <div class="grid md:grid-cols-2 gap-4 px-4 md:px-0 ">
                  <div>
                    <label class="sr-only">First Name</label>
                    <input
                      v-model="first_name" type="text" placeholder="FIRST NAME"
                      class="pb-1 border-b-2 border-zinc-300 bg-transparent w-full text-lg outline-none"
                    >
                    <span class="text-xs mt-1">
                      {{ errors.first_name }}
                    </span>
                  </div>
                  <div>
                    <label class="sr-only">Last Name</label>
                    <input
                      v-model="last_name" type="text" placeholder="LAST NAME"
                      class="pb-1 border-b-2 border-zinc-300 bg-transparent w-full text-lg outline-none"
                    >
                    <span class="text-xs mt-1">
                      {{ errors.last_name }}
                    </span>
                  </div>
                </div>
                <div class="grid md:grid-cols-2 gap-4 px-4 md:px-0">
                  <div>
                    <label class="sr-only">Email</label>
                    <input
                      v-model="email" type="text" placeholder="EMAIL"
                      class="pb-1 border-b-2 border-zinc-300 bg-transparent w-full text-lg outline-none"
                    >
                    <span class="text-xs mt-1">
                      {{ errors.email }}
                    </span>
                  </div>
                  <div>
                    <label class="sr-only">Zipcode</label>
                    <input
                      v-model="zipcode" type="text" placeholder="ZIP CODE"
                      class="pb-1 border-b-2 border-zinc-300 bg-transparent w-full text-lg"
                    >
                    <span class="text-xs mt-1">
                      {{ errors.zipcode }}
                    </span>
                  </div>
                </div>

                <div class="flex flex-col space-y-4  px-8 md:px-0">
                  <div>
                    <label class="relative flex items-center space-x-4">
                      <input v-model="agree_to_terms" type="checkbox">
                      <div v-html="terms" />
                      <span class="absolute -left-4 -bottom-4 text-xs">
                        {{ errors.agree_to_terms }}
                      </span>
                    </label>
                  </div>
                  <div v-if="travelAgent">
                    <label class="flex items-center space-x-4">
                      <input v-model="isTravelAgent" type="checkbox">
                      <div v-html="travelAgent" />
                    </label>
                  </div>
                </div>

                <fieldset class="flex justify-center">
                  <input
                    type="button" value="Continue" class="glue-btn btn-lg w-[300px] bg-glueblue-600 text-white cursor-pointer"
                    @click="onSubmit"
                  >
                </fieldset>
              </div>

              <GlobalUIPhoneForm v-else-if="step === 2" :form-data="formData" @next-step="setNextStep" />

              <div v-else class="col-span-7 py-16 lg:pr-8 flex items-center">
                <div class="uppercase text-center text-glueblue-600 font-sans text-2xl font-light p-4">
                  Thank you for completing the form!
                </div>
              </div>
            </div>
          </div>
        </div>
      </Teleport>
    </ClientOnly>
  </div>
</template>
